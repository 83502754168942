.user_sessions_wrapper {
    height: calc(100vh - 200px);
    overflow: auto;

    .card {
        background-color: #f7f7f7;
        box-shadow: unset !important;
        margin-bottom: 10px;

        .session_item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 20px;
            height: 50px;

            .session_item_left {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 20px;
                }
            }

            .session_item_right {
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    font-weight: 400;
                    width: 190px;
                }

                button {
                    margin-left: 20px;
                }

            }

            h5 {
                margin-bottom: unset;
            }

            .disconnect_btn {
                width: 130px;
            }
        }

    }
}